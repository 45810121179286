define("ember-50k/routes/team/team-bio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['bio'],

    afterModel(bio, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: bio.name,
        description: bio.title,
        url: 'https://www.50000feet.com/team/' + bio.slug,
        imgSrc: bio.image
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        $("#body").removeClass("load");
      }

      ;
    },

    model(params) {
      return this.store.queryRecord('bio', {
        slug: params.slug
      });
    }

  });

  _exports.default = _default;
});