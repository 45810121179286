define("ember-50k/routes/careers/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['careers'],

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: 'Careers',
        description: 'We’re always looking for forward-thinking individuals passionate about creating exceptional brand experiences.',
        url: 'https://www.50000feet.com/careers/'
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model() {
      return this.store.findAll("job");
    }

  });

  _exports.default = _default;
});