define("ember-50k/routes/carbone-smolan-new-york", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['csa'],

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: page.metatags.title,
        description: page.metatags.description,
        url: 'https://www.50000feet.com/carbone-smolan-new-york/',
        plyr: true
      };

      if (page.metatags.image_src != undefined) {
        this.metaInfo.imgSrc = page.metatags.image_src;
      }

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        document.addEventListener('lazyloaded', e => {
          AOS.refresh();
        });
        (0, _jquery.default)("#body").removeClass("load");
      }
    },

    model() {
      return this.store.queryRecord('page', {
        slug: 'carbone-smolan-new-york'
      });
    },

    loadPlugin: function () {
      if (this.fastboot.isFastBoot) {
        return;
      } else {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var css = (0, _jquery.default)("<link>", {
            rel: 'stylesheet',
            type: 'text/css',
            href: '/assets/plyr.css'
          });
          css.appendTo(css);
        });
      }
    }.on('init')
  });

  _exports.default = _default;
});